import React from "react";
import PropTypes from "prop-types";

import { Row } from "react-bootstrap";
import SectionHeader from "components/SectionHeader";
import AwardItem from "components/AwardItem";
import PageSection from "components/PageSection";

import "./Opinion.scss";

const Opinion = ({ className, frontmatter }) => {
  if (!frontmatter) {
    return null;
  }

  const { anchor, header: rootHeader, awards } = frontmatter;

  return (
    <PageSection className={className} id={anchor}>
      <Row>
        <SectionHeader header={rootHeader} />
      </Row>
      <Row>
      <ul className="awards-container" >
        {awards.map((award) => (
          <li key={award}> <AwardItem {...award} /></li> 
          
        ))}
        </ul>
      </Row>
    </PageSection>
  );
};

Opinion.propTypes = {
  className: PropTypes.string,
  frontmatter: PropTypes.object,
};

Opinion.defaultProps = {
  className: null,
  frontmatter: null,
};

export default Opinion;
