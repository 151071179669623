import React from "react";
import PropTypes from "prop-types";

import CircleIcon from "components/CircleIcon";

const Twitter = ({twitter}) => (
  <CircleIcon href="https://twitter.com/Prof_Edwards" iconName="TwitterIcon" socialLabel={twitter}/>
);

Twitter.propTypes = {
  twitter: PropTypes.string,
};

Twitter.defaultProps = {
  twitter: "",
};


export default Twitter;
