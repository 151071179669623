import React from "react";
import PropTypes from "prop-types";

import "./AwardItem.scss";

const AwardItem = ({ year, finalist, award }) => {
  

  return (
    <>
      <h3 className="award"><b>{year}</b>{finalist}<em>{award}</em></h3>
      <hr className="award-line" />
    </>
  );
};

AwardItem.propTypes = {
  year: PropTypes.string,
  finalist: PropTypes.string,
  award: PropTypes.string,
};

AwardItem.defaultProps = {
  year: "",
  finalist: "",
  award: "",
};

export default AwardItem;
