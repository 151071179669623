import React from "react";
import PropTypes from "prop-types";

import Image from "components/Image";
import "./ImageCard.scss";

const ImageCard = ({ imageFileName, header, subheader, center, prize, finalist, extraInfo }) => {
  return (
    <div className="header-container">
      <Image className="image" fileName={imageFileName} alt="" />
      <div className="text-container">
      <div className="intro-text">
              <div className="intro-text-wrapper">
              
                <div className="header-info">
                  {/* <p className="center-label">{center} </p> */}
                  <h2 className="finalist-text">{prize}</h2>
                  <p className="finalist-label">{finalist}</p>
                </div>
                <div className="header-content">
                  <div className="dash" />
                  <p className="intro-lead-in"><span>&quot;</span>{header}</p>
                  <p className="intro-lead-in">{subheader}<span>&quot;</span></p>
               
                  {/* {extraInfo} */}
                    
                </div> 
              </div>
            </div>
      </div>
    </div>
  );
};

ImageCard.propTypes = {
  imageFileName: PropTypes.string,
  header: PropTypes.string,
  subheader: PropTypes.string,
  extraInfo: PropTypes.any,
  center: PropTypes.string,
  prize: PropTypes.string,
  finalist: PropTypes.string,
};

ImageCard.defaultProps = {
  imageFileName: null,
  header: "",
  subheader: "",
  extraInfo: null,
  center: "",
  prize: "",
  finalist: "",
};

export default ImageCard;
