import React from "react";
import PropTypes from "prop-types";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "./TeamMember.scss";

const TeamMember = ({
  imageFileName,
  title,
  subheader,
  link,
  newWindow
}) => {

  return (
      <article>
        <h3 className="article-title">
          <a href={link}>{title}<span aria-hidden><FontAwesomeIcon icon={faExternalLinkAlt} /></span></a>
        </h3>
        <p className="publisher">{subheader}</p>
      </article> 
  );
};

TeamMember.propTypes = { 
  imageFileName: PropTypes.string,
  title: PropTypes.string,
  subheader: PropTypes.string,
  link: PropTypes.string,
  newWindow: PropTypes.string,
  
};

TeamMember.defaultProps = {
  imageFileName: "",
  title: "",
  subheader: "",
  link: "",
  newWindow: "",
};

export default TeamMember;
