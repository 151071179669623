import React, {useState} from "react";
import PropTypes from "prop-types";

import { Container, Row, Col, Button } from "react-bootstrap";
import { Dialog } from "@reach/dialog";
import "@reach/dialog/styles.css";
import * as SocialIcons from "components/SocialIcons";
import { faTimes, faHeart } from "@fortawesome/free-solid-svg-icons";
import './footer.scss';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Footer = ({ frontmatter }) => {
  const [showDialog, setShowDialog] = useState(false);
  const open = () => setShowDialog(true);
  const close = () => setShowDialog(false);

  if (!frontmatter) {
    return null;
  }

  const {
    copyright,
    privacyText,
    termsText,
    header,
    closeButton,
    content,
    header2,
    content2,
    content3,
    content4,
    header3,
    content5,
    content6,
    content7,
    content8,
    linkedin,
    twitter

  } = frontmatter;

  

  return (
    <footer className="footer py-3">
      <Container>
        <Row className="footer-content">
          <Col lg={6} className="my-3 my-lg-0">
            <div className="social-icons">
              <SocialIcons.Twitter />
              <SocialIcons.Linkedin />
            </div>
            <div>
              {copyright}
            </div>
          </Col>
          <Col lg={4} className="my-3 my-lg-0">     
            <div>
              <Button variant="outline-primary" className="ghost-btn" onClick={open}>{content6}</Button>
              <div>
                <a className="mr-3 py-3" href="mailto:info@consideringa11y.com">
                  {termsText} 
                    <span aria-hidden><FontAwesomeIcon icon={faHeart} aria-label="love"/> </span>{privacyText}
                  </a>
              </div>
            </div>  
          </Col>
          <div>
            <Dialog isOpen={showDialog} className="a11y-dialog" aria-label={header} onDismiss={close}>
              <div role="document">
                <div>
                  <Button className="close-btn primary" onClick={close}>
                    <p className="sr-only">{closeButton}</p>
                    <span aria-hidden><FontAwesomeIcon icon={faTimes} /></span>
                  </Button>
                </div>
                
                <h2>{header}</h2>
                <hr />
                <p>{content}.</p>
                <h3>{header2}?</h3>
                <p>{content2} <a href="mailto:info@consideringa11y.com">Considering A11y</a>{content3} <a href="https://www.w3.org/TR/WCAG21/">{content4}</a>.</p> 
                <h3>{header3}</h3>
                <p>{content5} <a href="mailto:info@consideringa11y.com">info@consideringA11y.com</a></p>
                <h3>{content7}</h3>
                <p>{content8}</p>
              </div> 
            </Dialog>
          </div>
        </Row>
      </Container>
    </footer>
  );
};

Footer.propTypes = {
  frontmatter: PropTypes.object,
};

Footer.defaultProps = {
  frontmatter: null,
};

export default Footer;
