import React from "react";
import PropTypes from "prop-types";

import CircleIcon from "components/CircleIcon";

const Linkedin = ({linkedin}) => (
  <CircleIcon href="https://www.linkedin.com/in/erika-edwards-b0203617/" iconName="LinkedinIcon" socialLabel={linkedin} />
);

Linkedin.propTypes = {
  linkedin: PropTypes.string,
};

Linkedin.defaultProps = {
  linkedin: "",
};


export default Linkedin;
