/* eslint-disable jsx-a11y/control-has-associated-label */
import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

import { Col } from "react-bootstrap";
import "./SectionHeader.scss";
import { faLink } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const SectionHeader = ({ header, className, ...restProps }) => {

  return (
    <>
    <Col lg={12} className={clsx("section-header", "text-center", className)} {...restProps}>
      
      <h2 className="section-heading text-uppercase">{header}</h2>
     
      {/* {subheaderPart} */}
    </Col>
    <Col lg={12}>
      <div className="header-link">
        <a href={`/#${header}`} aria-label={`Permalink for ${header}`} title={`Permalink for ${header}`}><FontAwesomeIcon icon={faLink} className="fa-lg" /></a> 
      </div>
    </Col>
     
     </>
  );
};

SectionHeader.propTypes = {
  header: PropTypes.string,
  className: PropTypes.string,
};

SectionHeader.defaultProps = {
  header: "",
  className: null,
};

export default SectionHeader;
