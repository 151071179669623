import React from "react";
import PropTypes from "prop-types";

import "./ServiceItem.scss";
import bookCover from "content/assets/images/services/Hiding.jpeg";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ServiceItem = ({ header, content, iconName, purchaseText, purchaseHref, newWindow }) => {
  

  return (
    <>
     <div className="book-container">
          <div className="left-section">
            <img src={bookCover} alt={header} className="book-cover" />
          </div>
          <div className="right-section">
            <h3><em>{header}</em></h3>
            <hr/>
            <p>{content}</p>
            <p>{iconName}</p>
            <div className="purchase">
            <a className=" purchase-link" target="_blank" href={purchaseHref} rel="noreferrer">{purchaseText}<span aria-hidden><FontAwesomeIcon icon={faExternalLinkAlt} /></span><span className="sr-only">{newWindow}</span></a>  
            </div>   
          </div>
        </div>
    </>
  );
};

ServiceItem.propTypes = {
  header: PropTypes.string,
  content: PropTypes.string,
  iconName: PropTypes.string,
  purchaseText: PropTypes.string,
  purchaseHref: PropTypes.string,
  newWindow: PropTypes.string,
};

ServiceItem.defaultProps = {
  header: "",
  content: "",
  iconName: "",
  purchaseText: "",
  purchaseHref: "",
  newWindow: "",
};

export default ServiceItem;
